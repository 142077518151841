import React from "react";
import moment from 'moment';
import { ToastContainer} from 'react-toastify';
import * as FaIcons from "react-icons/fa";
import i18n from "i18n-react";
import DefaultModal from '../../components/DefaultModal';
import DefaultSpinner from "../../components/DefaultSpinner";
import { DefaultAjax, modalForbidden } from "../../components/DefaultAjax";
import {  Row, Col, Table } from 'reactstrap';
import SelectGIE from '../../components/SelectGIE';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DefaultPagination from '../../components/DefaultPagination';
import {stopPropagation} from "../../components/SidebarFunctions";
import { downloadInvoicePdf, billingContentShow } from "./InvoiceFunctions";

export const url = process.env.REACT_APP_API_URL;

class ElectronicBillingIndex extends React.Component {
    constructor(props){
        super(props);
        this.state={
            modalContent:"",
            modalContent2:"",
            instanceType:"",
            instanceTypeDescription:"",
            independent:"",
            castMzate:"",
            spinner:"",
            token: props.token, 
            startDate: moment().add(),
            endDate: moment().add(),
            selectOrgType:"",
            dateRange: [],
            arrayElectronicBilling: [],
            currentBillingShow: "",
            creditNote: "",
            currentBillingPhase:0,
            customerBillingPhase: {},
            offset: 0,
            perPage: 24,
            currentPage: 1,
            paginationHtml:"",
            contentPagination: ""
        } 
        this.state.dateRange.push(this.dateRangeForSearh(new Date(moment())));
        this.state.dateRange.push(this.dateRangeForSearh(new Date(moment())));
    } 

    componentDidMount() {
        let token;
        if(!this.state.token){
            this.setState({token: document.querySelector("#token").value});
            token = document.querySelector("#token").value;
        }else{
            token = this.state.token;
            document.querySelector("#token").value = token;
        }


        let nav = document.querySelector("#nav-top").offsetHeight;
        let navbar = document.querySelector(".navbar").offsetHeight;
        let altura = window.innerHeight - nav - navbar - 80;
        let bodyContent = document.querySelector("#bodyContent");
        bodyContent.style.maxHeight = altura + "px";
        this.setState({
            spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").value}/>,
            selectOrgType: <SelectGIE classSelect={"col-sm-12 col-md-2 col-lg-2 col-xl-2 selectGIE"} classSelectType={"col-sm-12 col-md-2 col-lg-2 col-xl-2 displayNone"}/>
        });

        this.getAllBilling();
    } 

    handlePageClick = (e) => {
        const selectedPage = e.selected+1;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        });
        this.getAllBilling(true);
    };


    getAllBilling = (isSearch,isShowAll) => {
        this.setState({
            contentPagination: "",
            spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").value}/>
        });

        setTimeout(() => {
            let responsibleUsername = "";
            let customer = ""; 
            let documentNumber = "";
            let invoiceNumber = "";
            let startDateToSearch = this.state.dateRange[0];
            let endDateToSearch = this.state.dateRange[1];

            if(isSearch){
                if(document.querySelector("#userSelectedDescription").value == i18n.translate("default_all")){
                    responsibleUsername = "";
                }else{
                    responsibleUsername = document.querySelector("#usernameSelected").value;
                }  
                customer = document.querySelector("#customerToSearch").value;
                documentNumber = document.querySelector("#documentNumberToSearch").value;
                invoiceNumber = document.querySelector("#invoiceNumberToSearch").value;
            }else if(isShowAll){
                document.querySelector("#customerToSearch").value = '';
                document.querySelector("#documentNumberToSearch").value = '';
                document.querySelector("#invoiceNumberToSearch").value = '';
                document.querySelector(".DateRangePicker").value = ''
                startDateToSearch = "";
                endDateToSearch = "";
                this.setState({selectOrgType:""});
                setTimeout(()=> {
                    this.setState({selectOrgType: <SelectGIE classSelect={"col-sm-12 col-md-2 col-lg-2 col-xl-2 selectGIE"} classSelectType={"col-sm-12 col-md-2 col-lg-2 col-xl-2 displayNone"}/>
                    })
                }, 100);
            } 
            
            const params = new URLSearchParams({
                startDate: startDateToSearch,
                endDate: endDateToSearch,
                responsibleUsername:responsibleUsername,
                customer: customer,
                documentNumber: documentNumber,
                invoiceNumber: invoiceNumber,
                max : this.state.perPage,
                pageNumber : this.state.currentPage
            });
            DefaultAjax('').get(`/fePyInvoice?${params.toString()}`)
            .then((res) => {
                if(res){
                    let result = JSON.parse(res.data);
                    this.setState({
                        arrayElectronicBilling: result.invoiceList,
                        spinner:"",
                    })
                    const contentPagination = result.invoiceList.map((billing) =>{
                        return(
                            <tr key={billing.id} className='cellOptions' 
                            onClick={() => this.showElectronicBilling(billing)}>
                                <td>
                                    {billing.invoicingDate} 
                                </td>
                                <td>
                                    {billing.invoiceNumber} 
                                </td>
                                <td>
                                    {billing.customerName} 
                                </td>
                                <td>
                                    {billing.customerFiscalNumber} 
                                </td>
                                <td>
                                    {billing.exemptTotal} 
                                </td>
                                <td>
                                    {billing.vat05Total} 
                                </td>
                                <td>
                                    {billing.vat10Total} 
                                </td>
                                <td>
                                    {billing.netTotal} 
                                </td>
                                <td>
                                    {billing.responsible} 
                                </td>
                                <td>
                                    <button className='buttonMzateSecondary' onClick={(e)=>this.downloadBillingPdf(e,billing)}>{i18n.translate("download_pdf")}</button>
                                </td>
                            </tr>
                        )
                    })
                    this.setState({
                        pageCount: Math.ceil(result.totalCount / this.state.perPage),
                        
                        contentPagination
                    })
                    setTimeout(()=> {
                        this.setState({
                            paginationHtml:<DefaultPagination className={'mzatePaginationClass'}  data={result.invoiceList} 
                            handlePageClick={this.handlePageClick} perPage={this.state.perPage} totalCount={result.totalCount}
                            />})
                    }, 100);

                }
            }).catch((error) =>{
                this.setState({
                    modalContent:"",
                    spinner:"",
                    component:error.message
                });
                if (error.response) {
                    if(error.response.status === 403){
                        setTimeout(()=> { 
                            this.setState({modalContent:modalForbidden()});
                        }, 10);
                    } 
                }
            });
        }, 100);
    } 

    dateRangeForSearh = (date) => {
        let startDate = new Date(date);
        let m = startDate.getMonth() + 1;
        let mes = (m < 10) ? '0' + m : m;
        let day = startDate.getDate();
        let dayForShow = (day < 10) ? '0' + day : day;
        startDate = dayForShow+"-"+mes+"-"+startDate.getFullYear();
        return startDate;
    }

    handleCallback = (start, end, label) => {
        this.setState({dateRange:""})
        let dateRange = []; 
        document.querySelector("#dateInInputStart").value = this.dateRangeForSearh(start._d);
        document.querySelector("#dateInInputEnd").value = this.dateRangeForSearh(end._d);
        dateRange.push(this.dateRangeForSearh(start._d));
        dateRange.push(this.dateRangeForSearh(end._d));
        this.setState({
            dateRange:dateRange,
            startDate: start._d,
            endDate:end._d
        });
    }

    showElectronicBilling = (billing) => {
        this.setState({currentBillingShow:billing});
        this.setState({modalContent:""});
        let modalContent;
        modalContent = billingContentShow(billing);

        let modalTitle = i18n.translate("default_bill") + " " + billing.invoiceNumber;
        setTimeout(()=> {
            let buttons = [{text: i18n.translate("download_pdf"), function: this.downloadBillingPdf},
            {text: i18n.translate("default_close"), function: ""}];  
            this.setState({modalContent:<DefaultModal show={true} title={modalTitle}
            content={modalContent} buttons={buttons} size='lg'/>})
        }, 10);
    }

 
    showCreditNote = () => {
        this.setState({
            spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").value}/>,
            modalContent2:""
        });

        DefaultAjax('').get("electronicBilling/electronicBilling?billingId="+this.state.currentBillingShow.id)
        .then((res) => {
            if(res){
                this.setState({
                    spinner:""
                });
                let result = JSON.parse(res.data)
                let modalContent;
                modalContent = this.creditNoteContentShow(result);
                setTimeout(()=> {
                    let buttons = [{text: i18n.translate("default_close"), function: ""}];  
                    this.setState({modalContent2:<DefaultModal show={true} title={i18n.translate("credit_note")}
                    content={modalContent} buttons={buttons} size='lg'/>})
                }, 10);
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    } 

    creditNoteContentShow = (creditNote) => {
        return(
            <>
                <Row style={{textAlign:'center'}}>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("credit_number")} 
                        </label>
                        <br></br>
                        <label>
                            {creditNote.creditNoteNumber}  
                        </label>
                    </Col>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("date_issue")} 
                        </label>
                        <br></br>
                        <label>
                            {creditNote.dateIssue}  
                        </label>
                    </Col>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("expiration_date")} 
                        </label>
                        <br></br>
                        <label>
                            {creditNote.expirationDate}  
                        </label>
                    </Col>
                </Row>
                <Row style={{textAlign:'center'}}>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("invoice_fiscal_stamp_number")} 
                        </label>
                        <br></br>
                        <label>
                            {creditNote.invoiceFiscalStampNumber}  
                        </label>
                    </Col>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("invoice_customer_name")} 
                        </label>
                        <br></br>
                        <label>
                            {creditNote.customerName}  
                        </label>
                    </Col>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("invoice_sale_condition")} 
                        </label>
                        <br></br>
                        <label>
                            {creditNote.invoiceSaleCondition}  
                        </label>
                    </Col>
                </Row>
                <Row style={{textAlign:'center'}}>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("start_of_validity")} 
                        </label>
                        <br></br>
                        <label>
                            {creditNote.startOfValidity}  
                        </label>
                    </Col>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("invoice_customer_ruc")} 
                        </label>
                        <br></br>
                        <label>
                            {creditNote.customerFiscalNumber}  
                        </label>
                    </Col>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("default_currency")} 
                        </label>
                        <br></br>
                        <label>
                            {creditNote.currency}  
                        </label>
                    </Col>
                </Row>
                <Row style={{textAlign:'start'}}>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("reason_for_return")}:  
                        </label>
                        <label style={{marginLeft:'5px'}}>
                            {creditNote.reasonForReturn}  
                        </label>
                    </Col>
                </Row>
                <Table className='table table-hover'>
                    <tbody>
                        <tr>
                            <th className='firstcell'>{i18n.translate("default_code")}</th>
                            <th className='cellgroup'>{i18n.translate("description")}</th>
                            <th className='cellgroup'>{i18n.translate("storage_price")}</th>
                            <th className='cellgroup'>{i18n.translate("default_amount")}</th>
                            <th className='cellgroup'>{i18n.translate("default_iva")}</th>
                            <th className='cellgroup'>{i18n.translate("vat_description")}</th>
                            <th className='cellgroup'>{i18n.translate("vat_taxable_proportion")}</th>
                        </tr>
                        {creditNote.detail.map((det) =>{
                            return(
                                <tr key={det.code}>
                                    <td>
                                        {det.code} 
                                    </td>
                                    <td>
                                        {det.description} 
                                    </td>
                                    <td>
                                        {det.price} 
                                    </td>
                                    <td>
                                        {det.amount} 
                                    </td>
                                    <td>
                                        {det.vat} 
                                    </td>
                                    <td>
                                        {det.vatDescription} 
                                    </td>
                                    <td>
                                        {det.vatTaxableProportion} 
                                    </td>
                                </tr>
                            )
                        })} 
                    </tbody>
                </Table>
            </>
        )
    } 

    downloadBillingPdf = (e,billing) => {
        stopPropagation(e);

        if(!billing){
            billing = this.state.currentBillingShow
        } 

        this.setState({
            spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").value}/>
        });

        downloadInvoicePdf(billing)
        .then(success => {
            if (success) {
                this.setState({spinner:""})
            }
        })
        .catch(error => {
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });

    } 
    
    render(){
        return (
            <>
                <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover></ToastContainer>
                {this.state.modalContent}
                {this.state.modalContent2}
                <nav className='navbar'>
                    <Row style={{ width: '80%'}}>
                        {this.state.selectOrgType} 
                        <Col>
                            <label style={{display: "block"}}>{i18n.translate("date")}</label>
                            <div>
                                <DateRangePicker
                                    initialSettings={{ startDate: this.state.startDate, endDate: this.state.endDate }}
                                    onCallback={this.handleCallback}
                                >
                                    <input type="text" readOnly style={{fontSize: "12px",padding: "5px",cursor:"pointer"}} className="form-control inputStandard DateRangePicker" />
                                </DateRangePicker>
                                <input type="hidden" id="dateInInputStart"/>
                                <input type="hidden" id="dateInInputEnd"/>
                            </div>
                        </Col> 
                        <Col>
                            <label style={{display: "block"}}>{i18n.translate("default_client")}</label>
                            <input type="text" id="customerToSearch" className="form-control inputStandard"/>
                        </Col>
                        <Col>
                            <label style={{display: "block"}}>{i18n.translate("document_number")}</label>
                            <input type="text" id="documentNumberToSearch" className="form-control inputStandard"/>
                        </Col>
                        <Col>
                            <label style={{display: "block"}}>{i18n.translate("invoice_number")}</label>
                            <input type="text" id="invoiceNumberToSearch" className="form-control inputStandard"/>
                        </Col>
                    </Row>
                    <div style={{ paddingTop: "22px",width: '20%' }}>
                            <div style={{ display: 'inline-block' }} className="divIndex">
                                <button className='buttonMzateSecondary' onClick={()=> this.getAllBilling(true)}>
                                    <FaIcons.FaSearch />
                                    <span>{i18n.translate("default_search")}</span>
                                </button>
                            </div>
                            {' '}
                            <div style={{ display: 'inline-block' }} className="divIndex">
                                <button className='buttonMzateSecondary' onClick={()=> this.getAllBilling(false,true)}>
                                    <FaIcons.FaSearch />
                                    <span>{i18n.translate("default_show_all")}</span>
                                </button>
                            </div> 
                    </div>
                </nav>
                <br/>
                <div>
                    {this.state.spinner}
                    <div id="divTitle">
                        <span className="title">{i18n.translate("default_bills")}</span>
                        {this.state.paginationHtml}
                    </div>
                    <div className='bodyContent' id='bodyContent'>
                        <Table className='table table-hover'>
                            <tbody>
                                <tr>
                                    <th className='firstcell'>{i18n.translate("date_issue")}</th>
                                    <th className='cellgroup'>{i18n.translate("invoice_number")}</th>
                                    <th className='cellgroup'>{i18n.translate("invoice_customer_name")}</th>
                                    <th className='cellgroup'>{i18n.translate("document_number")}</th>
                                    <th className='cellgroup'>{i18n.translate("invoice_exempt")}</th>
                                    <th className='cellgroup'>{i18n.translate("invoice_vat_5")}</th>
                                    <th className='cellgroup'>{i18n.translate("invoice_vat_10")}</th>
                                    <th className='cellgroup'>{i18n.translate("net_total")}</th>
                                    <th className='cellgroup'>{i18n.translate("default_user")}</th>
                                    <th className='cellgroup'>{i18n.translate("download_pdf")}</th>
                                </tr>
                                {this.state.contentPagination}
                                {this.state.arrayElectronicBilling.length == 0 && <tr>
                                        <td colSpan={10} style={{textAlign:'center'}}>
                                            {i18n.translate("there_are_no_results_for_the_search")} 
                                        </td>
                                    </tr>} 
                            </tbody>
                        </Table>
                    </div>
                </div>
            </>
        )
    } 
} 

export default ElectronicBillingIndex;