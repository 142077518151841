import React from "react";
import DefaultSpinner from "../../components/DefaultSpinner";
import { modalForbidden } from "../../components/DefaultAjax";
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import {stopPropagation} from "../../components/SidebarFunctions";
import { downloadInvoicePdf, billingContentShow } from "./InvoiceFunctions";

export const url = process.env.REACT_APP_API_URL;
class InvoiceShow extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            modalContent:"",
            modalContent2:"",
            instanceType:"",
            instanceTypeDescription:"",
            independent:"",
            castMzate:"",
            spinner:"",
            currentBillingShow: "",
            invoiceId: props.invoiceId,
            token: props.token
        } 
    } 

    componentDidMount(){
        window.addEventListener('message', this.handleMessage);
        let token;
        if(!this.state.token){
            this.setState({
                token: document.querySelector("#token").value
            });
            token = document.querySelector("#token").value;
        }else{
            token = this.state.token;
            document.querySelector("#token").value = token;
        }
        this.getInvoiceToShow();
    } 

    handleMessage = (event) => {
        let platformUrl = document.querySelector("#platformUrl").value;
        if (platformUrl.endsWith('/')) {
            platformUrl = platformUrl.slice(0, -1); 
        }

        if (event.origin !== platformUrl) return;
        
        let type = event.data.type;
        if (type === 'DOWNLOAD_BILLING') {
            this.downloadBillingPdf(event);
        }
    };

    getInvoiceToShow = () => {
        this.setState({spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
        independent={document.querySelector("#independent").value}/>});
        setTimeout(()=> {
            if(document.querySelector(".modal.fade.show") != null){
                document.querySelector(".modal.fade.show").style.background = 'white';
            } 
        }, 100);
        
        axios({
            method: 'GET',
            url: url+"fePyInvoice/show?id="+this.state.invoiceId,
            headers: {'Authorization': document.querySelector("#token").value},
        }).then(response=>{  
            this.setState({
                spinner:""
            });      
            this.showElectronicBilling(response.data,true)                                                                                                                                                                                                                                              
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    } 

    showElectronicBilling = (billing, isFormPlatform) => {
        this.setState({currentBillingShow:billing});
        this.setState({modalContent:""});
        let modalContent;
        modalContent = billingContentShow(billing);
        this.setState({modalContent:modalContent})
    }

    downloadBillingPdf = (e,billing) => {
        stopPropagation(e);

        if(!billing){
            billing = this.state.currentBillingShow
        } 

        downloadInvoicePdf(billing)
        .then(success => {
            if (success) {
                this.setState({spinner:""})
            }
        })
        .catch(error => {
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });

    }

    componentWillUnmount() {
        window.removeEventListener('message', this.handleMessage);
    }

    render(){
        return(
            <>
                <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover></ToastContainer>
                {this.state.modalContent}
                {this.state.modalContent2}
                {this.state.spinner}
            </>
        )
    } 
} 

export default InvoiceShow;
